import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const TermotransferPage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Termotransfer" />
      <h1>Termotransfer</h1>
      <p className="text-justify">
        Metoda vhodná pro malá množství a proměnlivé údaje při zachování
        příznivé ceny. Kompletní zpracování probíhá pouze na úrovni softwaru,
        nejsou potřeba žádné tiskové matrice, štočky, nevznikají náklady na
        přípravu tisku. To je vykoupeno jednodušším vzhledem a omezenou
        barevností. Takto vyrobené etikety jsou dnes nejběžnějším typem, se
        kterým se setkáváte v obchodech, v běžné konfekci či prádle. Silnou
        stránkou je tisk malých serií nebo proměnlivých údajů. Zařízení může
        zpracovat excelová data a teoreticky může mít každá etiketa zcela jiné
        údaje. Připravujete výrobu např. košil, v různých velikostech, různých
        barvách či fazónách a různých počtech. Toto zvládne termotransfer bez
        problémů. Tištěný motiv vzniká „připečením“ pigmentu na povrch
        polyesterové stuhy. Trvanlivost tisku této metody není dokonalá, pigment
        je pouze na povrchu látky, není v jeho struktuře, jako je to u tkaných
        nebo sublimovaných etiket. Podkladový materiál je povrstvenný polyester,
        který se netřepí nebo polyesterový jemný saten, který je jemnější, ale
        ve střihu se třepí.
      </p>
      <p className="my-5">Na všechny materiály máme certifikát OEKO-TEX.</p>
      <div className="justify-center flex my-5">
        <StaticImage
          src="../images/termotransfer/ukazky-1.png"
          alt="Sublimace"
        />
      </div>
      <div className="justify-center flex my-5">
        <StaticImage
          src="../images/termotransfer/ukazky-2.png"
          alt="Sublimace"
        />
      </div>
      <div className="justify-center flex my-5">
        <table>
          <tr>
            <td className="font-bold pr-10">Minimální množství</td>
            <td>5 kusů</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Podkladový materiál</td>
            <td>
              <ul>
                <li>
                  povrstvený polyester bílý (A2), šířky 10, 15, 20,25, 30, 35,
                  40, 45, 50 mm Tisk může být oboustranný, barvy do 2/1
                </li>
                <li>
                  polyesterový jemný satén (A5), šířky 15, 20, 25, 30, 35, 40,
                  45, 50 mm, barva bílá, černá, červená, modrá (Barevné
                  materiály nejsou dosupné ve všech výše uvedených šířkách.)
                  Tisk pouze jednostranný, barvy 2/0
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Barvy</td>
            <td>
              Barva tisku černá, červená, modrá, zelená, žlutá, oranžová,
              růžová, šedá, bílá, zlatá, stříbrná
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Výška etikety</td>
            <td>Libovolná od 25 mm</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Cena</td>
            <td>
              Cena je závislá na velikosti etikety, barevnosti a objednaném
              množství
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Dodací lhůta</td>
            <td>1 týden od potvrzení objednávky</td>
          </tr>
        </table>
      </div>
      <p>
        Poptávky a jakékoli dotazy či připomínky ohledně etiket zasílejte na:{" "}
        <a className="font-bold" href="mailto:tistene@etka.cz">
          tistene@etka.cz
        </a>
      </p>
    </Layout>
  );
};

export default TermotransferPage;
